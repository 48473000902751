// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cancelled-js": () => import("./../../../src/pages/cancelled.js" /* webpackChunkName: "component---src-pages-cancelled-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-collection-bespoke-js": () => import("./../../../src/pages/collection/bespoke.js" /* webpackChunkName: "component---src-pages-collection-bespoke-js" */),
  "component---src-pages-collection-bracelets-js": () => import("./../../../src/pages/collection/bracelets.js" /* webpackChunkName: "component---src-pages-collection-bracelets-js" */),
  "component---src-pages-collection-decollete-js": () => import("./../../../src/pages/collection/decollete.js" /* webpackChunkName: "component---src-pages-collection-decollete-js" */),
  "component---src-pages-collection-earrings-js": () => import("./../../../src/pages/collection/earrings.js" /* webpackChunkName: "component---src-pages-collection-earrings-js" */),
  "component---src-pages-collection-homme-js": () => import("./../../../src/pages/collection/homme.js" /* webpackChunkName: "component---src-pages-collection-homme-js" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-collection-rings-js": () => import("./../../../src/pages/collection/rings.js" /* webpackChunkName: "component---src-pages-collection-rings-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-ringsizechart-js": () => import("./../../../src/pages/ringsizechart.js" /* webpackChunkName: "component---src-pages-ringsizechart-js" */),
  "component---src-pages-shipping-returns-js": () => import("./../../../src/pages/shipping-returns.js" /* webpackChunkName: "component---src-pages-shipping-returns-js" */),
  "component---src-pages-submitted-js": () => import("./../../../src/pages/submitted.js" /* webpackChunkName: "component---src-pages-submitted-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-wishlist-js": () => import("./../../../src/pages/wishlist.js" /* webpackChunkName: "component---src-pages-wishlist-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/ProductTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

